<template>
  <div class="guide-cnt">
    <h6 class="h6">购物流程</h6>
    <div class="cnt">
      <p class="p">1、选择产品：在商城浏览您要购买的产品，点击进入产品详情页；</p>
      <img class="img" src="../../assets/img/guide/shop.jpg">
      <p class="p">2、选择产品规格：选择产品的相关规格，点击立即购买或加入购物车后统一结算；</p>
      <img class="img" src="../../assets/img/guide/shop1.jpg">
      <p class="p">3、在线支付：提交订单后，通过支付宝/微信支付，若支付成功，您的订单就可以列入生产啦。</p>
      <img class="img" src="../../assets/img/guide/olpy.jpg">
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;
    }
    .img {
      display: block;width: 100%;margin:10px 0 20px 0px;
    }
  }
}
</style>
